.header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 20px;
  min-height: 10rem;
  color: $light-color-header;
  // Default
  background: $light-background-header;

  @media (prefers-color-scheme: dark) {
    background: $dark-background-header;
  }

  @media (prefers-color-scheme: light) {
    background: $light-background-header;
  }

  [data-theme=dark] & {
    background: $dark-background-header;
  }

  [data-theme=light] & {
    background: $light-background-header;
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media #{$media-size-phone} {
      flex-direction: row-reverse;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
  }
  
  &__image_container.mobile {
    display: none;
    @media #{$media-size-phone} {
      display: block;
    }
  }

  &__image_container.desktop {
    display: block;
    @media #{$media-size-phone} {
      display: none;
    }
  }

  &__image_container {
    position: relative;
    display: inline-block;
    & img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__overlay_text {

      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0%);
      color: white;
      font-size: 24px;
      padding: 10px;
      border-radius: 5px;
  }


}

.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  cursor: pointer;
}

.theme-toggler {
  fill: currentColor;
}

.not-selectable {
  user-select: none;
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
}