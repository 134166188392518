@charset "UTF-8";

/* Light theme color */
$light-background: #fff;
$light-background-secondary: #eaeaea;
$light-background-header: #003a74;
$light-color-header: #eaeaea;
$light-color: #222;
$light-color-variant: #041e49;
$light-color-secondary: #0d4d9d;
$light-border-color: #dcdcdc;
$light-table-color: #dcdcdc;

/* Dark theme colors */
$dark-background: #fff;
$dark-background-secondary: #eaeaea;
$dark-background-header: #003a74;
$dark-color-header: #eaeaea;
$dark-color: #222;
$dark-color-variant: #041e49;
$dark-color-secondary: #0d4d9d;
$dark-border-color: #dcdcdc;
$dark-table-color: #dcdcdc;

$media-size-phone: "(max-width: 684px)";
$media-size-tablet: "(max-width: 900px)";

/* Variables for js, must be the same as these in @custom-media queries */
:root {
  --phoneWidth: (max-width: 684px);
  --tabletWidth: (max-width: 900px);
}

/* Content */
$max-width: 860px;