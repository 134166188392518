.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  font-display: auto;
  font-family: monospace, monospace;
  margin-left: auto;
  margin-right: auto;

  img {
    height: 5rem;

    @media #{$media-size-phone} {
      height: 3rem;
    }
  
  }

  &__mark {
    margin-right: 5px;
  }

  &__text {
    font-size: 1.125rem;
    white-space: nowrap;
  }


}
